export const checkoutText = {
  componentsText: {
    "Cardholder name": "Name (as it appears on card)",
    "ID number": "DNI/CUIT",
    "Pay now": "Order now",
    "Pay before:": "PAY BEFORE",
    "Payment code": "CODE",
  },
};

export const checkoutCustomer = {
  firstName: "Nahuel",
  lastName: "Candia",
  email: "nahuel@gmail.com",
  phone: {
    countryCode: "54",
    areaCode: "11",
    phoneNumber: "1122530654",
  },
  personalId: {
    type: "DNI",
    value: "34998123",
  },
  address: {
    street: "El Salvador",
    number: "5218",
    city: "CABA",
    state: "Buenos Aires",
    zipCode: "C14X",
    country: "AR",
  },
};

export const checkoutStyles = {
  rebill_skeleton_theme: "dark",
  rebill_alternative_checkout: {
    borderRadius: "0.25rem",
    padding: "0",
    border: "none",
    gap: "2rem",
  },
  rebill_alternative_checkout_container: {
    borderRadius: "0.25rem",
    border: "2px solid #343434",
    padding: "1rem 1rem",
    gap: "2rem",
  },
  rebill_pay_before: {
    backgroundColor: "#fff",
    color: "#000",
  },
  rebill_svg_color: "#fff",
  rebill_divider_span: {
    color: "#fff",
    backgroundColor: "#000",
  },
  rebill_divider: {
    color: "#fff",
  },
  rebill_container: {
    padding: 0,
    color: "#fff",
  },
  rebill_option: {
    backgroundColor: "#000",
    border: "2px solid #343434",
    borderRadius: "0.25rem",
  },
  rebill_pay_btn: {
    borderRadius: "0.25rem",
    padding: "0.8rem",
    marginTop: "0.5rem",
  },
  rebill_download_btn: {
    borderRadius: "0.25rem",
    padding: "0.8rem",
    color: "#fff",
    backgroundColor: "#000",
    border: "1px solid #fff",
  },
  rebill_change_payment_method_btn: {
    borderRadius: "0.25rem",
    backgroundColor: "#fff",
    padding: "0.8rem",
    color: "#000",
  },
  rebill_cardholder_input: {
    margin: "0.5rem 0",
    backgroundColor: "#343434",
    border: "1px solid #000",
    color: "white",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    borderRadius: "0.25rem",
  },
  rebill_identification_input: {
    margin: "0.5rem 0",
    backgroundColor: "#343434",
    border: "1px solid #000",
    color: "white",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    borderRadius: "0.25rem",
  },
  rebill_card_input_wrapper: {
    margin: "0.2rem 0 0.5rem 0",
    backgroundColor: "#343434",
    border: "0.5px solid #fff",
    color: "white",
    padding: "1.7rem",
    borderRadius: "0.25rem",
  },
  rebill_cardholder_container: {},
  rebill_option: {
    backgroundColor: "#000",
    border: "2px solid #343434",
    borderRadius: "0.25rem",
  },
  rebill_options_container: {
    backgroundColor: "#000",
  },
  rebill_identification_select: {
    margin: "0.5rem 0",
    backgroundColor: "#343434",
    border: "1px solid #000",
    color: "#9ca3af",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    borderRadius: "0.25rem",
  },
  rebill_pay_before: {
    display: "flex",
    backgroundColor: "#343434",
    justifyContent: "space-between",
    padding: "1rem 1rem",
  },
  rebill_pay_before_icon: {
    display: "none",
  },
};
