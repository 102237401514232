import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./App.css";
import { RebillProvider } from "rebill-react";

const apiKey = "API_KEY_8717d83c-086d-42a5-a649-6f5c7c3b9ae1";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RebillProvider apiKey={apiKey}>
      <App />
    </RebillProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
