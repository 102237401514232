import React from "react";
import { Switch, Route } from "react-router-dom";
import LandingScreen from "./modules/landing/landing.screen";
import OrdersScreen from "./modules/orders/orders.screen";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={LandingScreen} />
      <Route exact path="/orders" component={OrdersScreen} />
    </Switch>
  );
};

export default Routes;
