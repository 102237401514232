import { useEffect } from "react";
import { Checkout as RebillCheckout, useRebill } from "rebill-react";

import {
  checkoutCustomer,
  checkoutStyles,
  checkoutText,
} from "./rebill-sdk.helper";

const onError = (e) => console.log("Error", e);

export default function Checkout({
  setLoading,
  setDisabled,
  setPaymentProcessed,
}) {
  const { setTransaction, setCustomer, setStyles, setCallbacks, setText, sdk } =
    useRebill();

  const onSuccess = (e) => {
    console.log(e);

    setPaymentProcessed(true);
  };

  const onLoading = (isLoading) => {
    setLoading(isLoading);
  };
  const onDisabled = (isDisabled) => {
    setDisabled(isDisabled);
  };

  useEffect(() => {
    setTransaction({
      id: "c1351923-2428-4004-b35a-c3310c9a871a",
      currency: "ARS",
      quantity: 1,
    });
    setCustomer(checkoutCustomer);
    setStyles(checkoutStyles);
    setText(checkoutText);
    setCallbacks(onSuccess, onError, onLoading, onDisabled);
  }, [sdk]);

  return (
    <>
      <div className='min-h-[33rem] mt-5'>
        <RebillCheckout />
      </div>
    </>
  );
}
