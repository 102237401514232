import React, { useState } from "react";
import { useRebill } from "rebill-react";

import image from "../../assets/background.png";
import Checkout from "../../components/rebill-sdk.component";

const OrdersScreen = () => {
  const { submitPaymentWithCard } = useRebill();
  const [loading, setLoading] = useState();
  const [disabled, setDisabled] = useState();
  const [isPaymentProcessed, setPaymentProcessed] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    if (!disabled && !loading) {
      submitPaymentWithCard();
    }
  };

  return (
    <div className="px-4 w-screen  md:pl-4">
      <nav className="md:fixed  md:top-0 md:left-0 md:right-0 bg-black z-50 flex justify-between w-full pt-6 md:h-[7%] items-center md:pt-0 lg:px-10">
        <div className="flex lg:justify-between lg:w-[40%]">
          <a href="/">
            <div className="starlink-logo h-auto md:w-[148px]"></div>
          </a>
          <div className="hidden xl:font-extrabold xl:flex lg:gap-20 xl:w-auto xl:items-end">
            <p>
              <a href="https://www.starlink.com/residential">RESIDENTIAL</a>
            </p>
            <p>
              <a href="https://www.starlink.com/roam">ROAM</a>
            </p>
            <p>
              <a href="https://www.starlink.com/boats">BOATS</a>
            </p>
          </div>
        </div>
        <div className="flex gap-6 lg:gap-14 justify-between">
          <div className="hidden md:gap-2 md:font-extrabold md:flex md:justify-between md:items-end">
            <p className="underline">
              <a href="https://www.starlink.com/">PERSONAL</a>
            </p>
            <p>|</p>
            <p>
              <a href="https://www.starlink.com/business">BUSINESSES</a>
            </p>
          </div>
          <img
            src="fonts/Menu_Hamburger.svg"
            className="avatar"
            alt="Open Menu"
          />
        </div>
      </nav>
      <main className="flex flex-col gap-6 mt-16  md:flex-row md:gap-16 md:mt-28 md:justify-end md:w-full md:pr-6">
        <section className="hidden md:block   md:w-[55%] md:p-0">
          <img
            src={image}
            width="45%"
            height="100%"
            className="hidden md:block md:fixed"
          />
        </section>
        <section className="w-full  md:w-[35%] md:relative">
          <h1
            className="header text-xl mb-3 md:text-2xl"
            style={{
              fontFamily: "DIN_Bold,Arial,Verdana,sans-serif",
            }}
          >
            ORDER STARLINK
          </h1>
          <div class="address-break h5 ng-star-inserted">
            {" "}
            <h2 className="font-extrabold text-xl md:mb-12">
              El Salvador 5218, Cdad. Autónoma de Buenos Aires, Argentina{" "}
            </h2>
          </div>
          <div className="intro-text ng-star-inserted leading-relaxed">
            <h2 class="fddbold16">Starlink is available at your address!</h2>
            Shipment will be made with DHL to your address without any
            additional charge after your payment.
            <p className="md:mt-3">
              <b>Order delivers in 2-3 weeks | No contracts | 30-day trial</b>
            </p>
            &nbsp;
            <details>
              <summary className="my-6">
                <u>Overview of products and services</u>
              </summary>
              <ul>
                <li>
                  <b>Performance: </b>Includes unlimited high-speed, low-latency
                  internet.
                </li>{" "}
                <li>
                  <b>Installation: </b>Simple setup using the Starlink app, no
                  professional installation required. Download the app on{" "}
                  <a
                    href="https://apps.apple.com/us/app/starlink/id1537177988"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    iOS
                  </a>{" "}
                  or{" "}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.starlink.mobile"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Android.
                  </a>
                </li>{" "}
                <li>
                  <b>Accessories: </b>Log in to your account to visit the
                  Starlink store after placing your order. You can check out our
                  selection of compatible accessories{" "}
                  <a
                    href="https://api.starlink.com/public-files/Accessories_Guide_Rectangular.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here.
                  </a>
                </li>{" "}
                <li>
                  <b>Billing: </b>You will receive your first monthly service
                  bill by activating your Starlink or 30 days after shipment,
                  whichever comes first.
                </li>{" "}
                <p> </p>
                <li>
                  <b>What's included</b>: Standard Starlink antenna, Wi-Fi
                  router, 15m (50ft) Starlink cable, router AC power cable, base
                  <img
                    width="300"
                    src="images/Standard%20V3%20-%20White.png"
                  />{" "}
                  <p>
                    Having trouble placing your order?{" "}
                    <a
                      href="https://starlink.typeform.com/to/YcyBBY0k#country=AR"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click here
                    </a>
                  </p>
                </li>
              </ul>
            </details>
          </div>
          <p className="w-full selected-price ng-star-inserted">
            ARS62,500/mo for service and ARS499,999 for hardware.
          </p>
          <div class="flex items-center mb-2 md:mt-3">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              class="w-4 h-4 bg-black border-gray-300 rounded dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              for="default-checkbox"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              <div>
                <span
                  _ngcontent-but-c124=""
                  id="boydAllCheckboxLabel"
                  className=""
                >
                  I already have my Starlink.
                </span>
                <span _ngcontent-but-c124="" class="ng-star-inserted">
                  <a
                    href="https://support.starlink.com/?topic=2802431a-135f-0671-4c1b-4cedb65b291a"
                    target="_blank"
                  >
                    Find my Starlink identifier
                  </a>
                </span>
              </div>
            </label>
          </div>
          <div>
            <h2 className="font-extrabold md:mt-6">Contact information</h2>
            <form className="flex flex-col my-6 gap-6">
              <div className="flex justify-between gap-3 md:gap-2">
                <input
                  type="text"
                  id="first-name"
                  name="first-name"
                  autoComplete="given-name"
                  value="Nahuel"
                  disabled
                  className="w-full block  px-2 py-3 rounded-sm bg-[#343434] border-gray-500 shadow-sm sm:text-sm"
                />
                <input
                  type="text"
                  id="first-name"
                  name="first-name"
                  autoComplete="given-name"
                  value="Candia"
                  disabled
                  className="w-full block  px-2 py-3 rounded-sm bg-[#343434] border-gray-500 shadow-sm sm:text-sm"
                />
              </div>
              <input
                type="text"
                id="first-name"
                name="first-name"
                autoComplete="given-name"
                value="+54 9 11 6686-2583"
                disabled
                className="block  px-2 py-3 rounded-sm bg-[#343434] border-gray-500 shadow-sm sm:text-sm"
              />
              <input
                type="text"
                id="first-name"
                name="first-name"
                autoComplete="given-name"
                value="nahuel@rebill.com"
                disabled
                className="block  px-2 py-3 rounded-sm bg-[#343434] border-gray-500 shadow-sm sm:text-sm"
              />
            </form>
          </div>
          <div id="shipping">
            <div className="flex justify-between w-full mb-2">
              <h2 className="font-extrabold md:mt-6">Shipping address</h2>
              <svg
                fit=""
                height="32"
                width="32"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <g transform="translate(0.5 0.5)">
                  <path
                    id="Path_1166"
                    data-name="Path 1166"
                    d="M-1814.876,2389.935l-1.541,5.78,6.036-1.623,13.921-14.061-4.4-4.316Z"
                    transform="translate(1816.418 -2375.715)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  ></path>
                </g>
              </svg>
            </div>
            <div className="flex flex-col gap-2">
              <p className="font-semibold text-md">
                {" "}
                El Salvador 5218, oficina 313
              </p>
              <p className="font-semibold text-md">
                {" "}
                EL SALVADOR CAPITAL FEDERAL C1414
              </p>
              <p className="font-semibold text-md"> AR</p>
            </div>
          </div>
          <div id="checkout">
            <h2 className="font-extrabold md:mt-6">Billing information</h2>
            <Checkout
              setDisabled={setDisabled}
              setLoading={setLoading}
              setPaymentProcessed={setPaymentProcessed}
            />
          </div>
          <div>
            <div id="resume" className="flex flex-col divide-y">
              <div className="flex justify-between py-3">
                <p className="text-lg">Service</p>
                <p className="text-md">ARS62,500.00/mo</p>
              </div>
              <div className="flex justify-between py-3">
                <p className="text-lg">Hardware</p>
                <p className="text-md">ARS499,999.00</p>
              </div>
              <div className="flex justify-between py-3">
                <p className="text-lg">Shipping & Handling</p>
                <p className="text-md">ARS31,000.00</p>
              </div>
            </div>
            <h2 className="font-extrabold text-2xl my-2">DUE TODAY</h2>
          </div>
          <button
            onClick={(e) => handleClick(e)}
            className={`
            ${disabled && "cursor-not-allowed"}
            ${!loading && !disabled && "cursor-pointer"} 
            w-[520px] h-16 font-bold flex items-center justify-center bg-[#585454] my-6 md:w-full md:my-6`}
            disabled={true}
            id="order-button"
          >
            <span> {loading ? "Processing payment..." : "Place Order "}</span>
          </button>
          <div className="flex flex-col gap-4 text-sm md:mb-[12rem] text-[#757575]">
            {" "}
            <div className="">
              By placing this order, I agree to the Starlink{" "}
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://starlink.com/legal?regionCode=AR"
                style={{ textDecoration: "underline" }}
              >
                {" "}
                Terms Of Service and Privacy Policy{" "}
              </a>
              . You can cancel the recurring payment at any time via your
              Starlink Account. Starlink may update its Service Terms and
              pricing as needed after providing the required notice to you. If
              your order fails and there is a red error message, we will still
              collect your email and phone number solely for the purpose of
              attempting to assist you in processing your order.{" "}
            </div>
            <span>
              <p>
                <a
                  style={{ textDecoration: "underline", margin: "1rem 0" }}
                  href="https://www.starlink.com/business?referral=RC-14215-95514-56&amp;utm_source=email"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ordering as a business? Learn more
                </a>
              </p>
            </span>
          </div>
        </section>
      </main>
      <footer className="hidden md:fixed md:flex md:flex-col md:gap-6  md:inset-x-0 bottom-0 bg-black text-white p-4 text-center">
        <div
          _ngcontent-but-c55=""
          className="md:flex md:gap-2 md:text-sm md:ml-10 footer-links-container"
        >
          <span _ngcontent-but-c55="" class="footer-link link-light">
            <a _ngcontent-but-c55="" href="https://www.spacex.com/careers">
              Careers
            </a>
          </span>
          <span _ngcontent-but-c55="" class="footer-link link-light">
            <a
              _ngcontent-but-c55=""
              href="https://starlink.com/satellite-operators"
            >
              Satellite Operators
            </a>
          </span>
          <span _ngcontent-but-c55="" class="footer-link link-light">
            <a _ngcontent-but-c55="" href="https://starlink.com/resellers">
              Authorized Reseller
            </a>
          </span>
          <span _ngcontent-but-c55="" class="footer-link link-light">
            <a _ngcontent-but-c55="" href="https://starlink.com/legal">
              Privacy &amp; Legal
            </a>
          </span>
          <span
            _ngcontent-but-c55=""
            class="footer-link link-light ng-star-inserted"
          >
            <a _ngcontent-but-c55="" href="#">
              Privacy Preferences
            </a>
          </span>
        </div>
        <div
          _ngcontent-but-c55=""
          className="footer-other-container md:flex md:gap-2 md:text-sm md:ml-10"
        >
          <div _ngcontent-but-c55="" class="text-copyright">
            <span _ngcontent-but-c55="">Starlink © 2024</span>
          </div>
          <div _ngcontent-but-c55="" class="text-division link-light">
            Starlink is a division of SpaceX. Visit us at{" "}
            <a
              href="https://www.spacex.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              spacex.com
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default OrdersScreen;
